<template>
  <path id="Tracé_565" data-name="Tracé 565" d="M31.255,62.512a4.466,4.466,0,0,1,0-8.932H35.72A4.458,4.458,0,0,1,39.9,56.529c7.2-.284,15.166-3.893,15.166-13.367V24.31C55.068,14.445,44.992,2.976,32,2.976S8.93,14.445,8.93,24.31V43.162H4.466A4.47,4.47,0,0,1,0,38.7V32.744a4.47,4.47,0,0,1,4.466-4.466H5.954V24.31C5.954,12.836,17.093,0,32,0S58.046,12.836,58.046,24.31v3.968h1.489A4.471,4.471,0,0,1,64,32.744V38.7a4.471,4.471,0,0,1-4.465,4.465H58.046c0,9.514-7.249,15.976-18.123,16.338a4.459,4.459,0,0,1-4.2,3.012Zm0-2.978H35.72a1.488,1.488,0,1,0,0-2.975H31.255a1.488,1.488,0,0,0,0,2.975Zm28.28-19.35A1.488,1.488,0,0,0,61.022,38.7V32.744a1.489,1.489,0,0,0-1.487-1.488H58.046v8.928Zm-55.069,0H5.954V31.256H4.466a1.489,1.489,0,0,0-1.489,1.487V38.7A1.489,1.489,0,0,0,4.466,40.184Zm7.442,8.932V26.79a8.941,8.941,0,0,1,8.929-8.931h8.932V8.929h2.976V17.86H43.163a8.941,8.941,0,0,1,8.93,8.931V49.116Zm37.209-2.977V26.79a5.96,5.96,0,0,0-5.953-5.954H20.837a5.96,5.96,0,0,0-5.953,5.954V46.139ZM26.79,43.162V40.185H37.209v2.977ZM38.7,37.209V29.767h2.976v7.441Zm-16.373,0V29.767H25.3v7.441Z" transform="translate(0.5 0.5)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
name: "IconUtilisateur"
}
</script>

<style scoped>

</style>